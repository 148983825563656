import React, { Component } from "react";
import styled from "styled-components";

export default class ScrollToTop extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {

    if (window.pageYOffset > 300) {
        this.setState({
          is_visible: true
        });
      } else {
        this.setState({
          is_visible: false
        });
      }
  }

  scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <ScrollContainer className="scroll-to-top" style={{top:50}}>
        {is_visible && (
          <ScrollText  onClick={() => this.scrollToTop()}>
            
              Scroll Up
          </ScrollText>
        )}
      </ScrollContainer>
    );
  }
}

const ScrollContainer = styled.div`

  margin:auto;
  z-index:10;
  position:sticky;
  width:fit-content;
  border-radius:50px;

  

  &:hover{
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    transform : scale(1.1,1.1)
  }
`;


const ScrollText =styled.div`

  border-radius:50px;
  color:white;
  background:#2D6DF6;
  padding:5px 12px;
  user-select:none;
`;