import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/analytics'

const config = {
    apiKey: "AIzaSyD61DEdvXYaZXnNMlF98Phz9bBScp2vs7M",
    authDomain: "covid-19-a3641.firebaseapp.com",
    databaseURL: "https://covid-19-a3641.firebaseio.com",
    projectId: "covid-19-a3641",
    storageBucket: "covid-19-a3641.appspot.com",
    messagingSenderId: "290293153265",
    appId: "1:290293153265:web:3a45b9105e7ba8fd8d5850",
    measurementId: "G-KGWN5G7P4H"
}
firebase.initializeApp(config);
firebase.analytics();
export default firebase;