import React, { Component } from 'react'
import * as topojson from 'topojson-client';
import * as d3 from 'd3v4';
import * as India from './india.json';
export default class IndiaMap extends Component {

    
    constructor(props)
    {
        super(props)
        this.width = 400;
        this.height = 600;
    
        this.projection = d3.geoMercator();
    
        this.path = d3.geoPath().projection(this.projection).pointRadius(2);
    }

    componentDidMount()
    {
        this.stateCases=this.props.stateCases

        // this.svg = d3.select(this.div).append('svg').attr("height",600).attr("width",600)
        // console.log('svg',this.svg);
        var margin = {top: 10, left: 10, bottom: 10, right: 20}
        // var width = parseInt(d3.select('#indiaMap').style('width'))
        var width = window.screen.width - margin.left - margin.right
        var height = 600
        var x = 0
        if(width>600){
            width = 1000
            x = -200
        }
        else{
            x=-20
            width = 430
        }
        
        this.svg = d3.select(this.div).append('svg').attr("viewBox",`${x} 0 ${width} ${height}`).attr("id","indiaMap").attr("preserveAspectRatio", "xMinYMin")
        this.g = this.svg.append("g");
            
        this.initializeMap()
        this.existSvg=true

        // this.initializeMap()
        this.updateTotalDiv()
        this.updateLastUpdatedText("Total")
        // console.log(this.props)
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    httpGet(theUrl){
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open( "GET", theUrl, false ); // false for synchronous request
        xmlHttp.send( null );
        return xmlHttp.responseText;
    }

    updateTotalDiv(){
        // console.log(this.stateCases)
        document.getElementById('ttConfirmed').innerHTML= this.getStateCasesStringFormatted("Total")["confirmed"]
        document.getElementById('ttActive').innerHTML= this.getStateCasesStringFormatted("Total")["active"]
        document.getElementById('ttDeaths').innerHTML= this.getStateCasesStringFormatted("Total")["deaths"]
        document.getElementById('ttRecovered').innerHTML= this.getStateCasesStringFormatted("Total")["recovered"]
        
    }

    createTotalDiv(){
        var totalDiv=d3.select('body').append('div').attr('class','row')
        totalDiv.append('div').attr('class','column').append('span').text('1000')
        totalDiv.append('div').attr('class','column').append('span').text('1000')
    }

    initializeMap(){
        
        // console.log(India);
        // var data=null
        // console.log(India.default)
        var boundary = this.centerZoom(India.default);
        var subunits = this.drawSubUnits(India.default);
        this.colorSubunits(subunits);
        // this.drawSubUnitLabels(India.default);
        // this.drawPlaces(India.default);
        this.drawOuterBoundary(India.default, boundary);
        var width = window.screen.width
        if( width>600){
            this.addLegend()
        }
        
        
       
    }


    drawOuterBoundary(data, boundary){

        // console.log("path",this.path);
        
        this.g.append("path")
            .datum(boundary)
            .attr("d", this.path)
            .attr("class", "subunit-boundary")
            .attr("fill", "none")
            .attr("stroke", "#3a403d");

    }
    getStateCases(stateName){
        // console.log(this.stateCases)
        var allCases = JSON.parse(this.stateCases)
        for (const element of allCases.statewise){
            if (element.state === stateName){
                return {
                    "confirmed":element.confirmed,
                    "active":element.active,
                    "deaths":element.deaths,
                    "recovered":element.recovered
                }
            }
        }
        return 0        
    }

    getStateCasesStringFormatted(stateName){
        // console.log(this.stateCases)
        var allCases = JSON.parse(this.stateCases)
        for (const element of allCases.statewise){
            if (element.state === stateName){
                return {
                    "confirmed":this.numberWithCommas(element.confirmed),
                    "active":this.numberWithCommas(element.active),
                    "deaths":this.numberWithCommas(element.deaths),
                    "recovered":this.numberWithCommas(element.recovered)
                }
            }
        }
        return 0        
    }

    getLastUpdatedDate(stateName){
        var allCases = JSON.parse(this.stateCases)
        for (const element of allCases.statewise){
            if (element.state === stateName){
                return element.lastupdatedtime             
                
            }
        }
        return 0   
    }

    updateLastUpdatedText(stateName){
        document.getElementById("lastUpdatedTxt").textContent = this.getLastUpdatedDate(stateName)
    }

    getColor(numberOfCases){
        if (numberOfCases<=1000){
            return "rgb(247, 252, 240)"
        }
        else if(numberOfCases<=5000){
            return "rgb(204, 235, 197)"
        }
        else if(numberOfCases<=10000){
            return "rgb(168, 221, 181)"
        }
        else if(numberOfCases<=20000){
            return "rgb(123, 204, 196)"
        }
        else if(numberOfCases<=40000){
            return "rgb(78, 179, 211)"
        }
        else{
            return "rgb(8, 104, 172)"
        }
    }

    colorSubunits(subunits) {

        var k= this
        // console.log(k.getStateCases('Maharashtra'))
        var stateCases = JSON.parse(this.stateCases)
        // var minInt=0
        var maxInt = 0 
        for (var element of stateCases.statewise){
            if (element.state==="Total"){
                continue
            }
            if (element.confirmed>maxInt){
                maxInt = parseInt(element.confirmed)
                // console.log("max: "+typeof maxInt)
            }
        }
        
        

        // var c = d3.scaleLinear().range(["#FFFFFF","#FF0000"]).domain([minInt,maxInt+20000])
        // console.log(stateCases.statewise)
        
        // console.log(c()
        subunits
            .style("fill", function(d,i){
                // console.log(d.properties.st_nm+" : "+k.getStateCases(d.properties.st_nm))
                return k.getColor(k.getStateCases(d.properties.st_nm)["confirmed"]); })
            .style("opacity", "0.8")
            .style("stroke","black");

    }
    drawSubUnits(data){
        var k = this
        var mouseover = function(d) {
        Tooltip
            .style("opacity", 1)
        
        k.setState({name:d.properties.st_nm})
        // console.log('over',d.properties.st_nm);
        
        d3.select(this)
            .style("stroke", "black")
            .style("stroke-width",2)
            .style("opacity", 1)
        }
        var mousemove = function(d) {
            var htmlText = `<b> ${d.properties.st_nm} </b> <br/><span style='color:green'>confirmed: ${k.getStateCasesStringFormatted(d.properties.st_nm)["confirmed"]}</span> <br/> <span style='color:red'>active: ${k.getStateCasesStringFormatted(d.properties.st_nm)["active"]}</span> <br/><span style='color:gray'>deaths: ${k.getStateCasesStringFormatted(d.properties.st_nm)["deaths"]}</span> `

        k.updateLastUpdatedText(d.properties.st_nm)
        Tooltip
            .html(htmlText)
            .style("left", (d3.event.pageX+50) + "px")
            .style("top", (d3.event.pageY) + "px");
            // .style("left", (d3.mouse(this)[0]+70) + "px")
            // .style("top", (d3.mouse(this)[1]) + "px")
        }
        var mouseleave = function(d) {
        Tooltip
            .style("opacity", 0)
        d3.select(this)
            .style("stroke", "black")
            .style("opacity", 0.8)
            .style("stroke-width",1)
          }

        var subunits = this.g.selectAll(".subunit")
            .data(topojson.feature(data, data.objects.polygons).features)
        .enter().append("path")
            .attr("class", "subunit")
            .attr("d", this.path)
            .style("stroke", "#3a403d")
            .style("stroke-width", "1px")
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)
        

        var Tooltip = d3.select(this.div)
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")

        return subunits;

    }
    centerZoom(data){
        // console.log("hello")
        // console.log(JSON.parse(this.stateCases))
        var o = topojson.mesh(data, data.objects.polygons, function(a, b) { return a === b; });

        this.projection.scale(1).translate([0, 0]);

        var b = this.path.bounds(o),
            s = 1 / Math.max((b[1][0] - b[0][0]) / this.width, (b[1][1] - b[0][1]) / this.height),
            t = [(this.width - s * (b[1][0] + b[0][0])) / 2, (this.height - s * (b[1][1] + b[0][1])) / 2];

        var p = this.projection
            .scale(s)
            .translate(t);

        return o;

    }

    getLegendText(numberOfCases){
        if (numberOfCases<=1000){
            return '0 - 1,000'
        }
        else if(numberOfCases<=5000){
            return "1,001 - 5,000"
        }
        else if(numberOfCases<=10000){
            return "5,001 - 10,000"
        }
        else if(numberOfCases<=20000){
            return "10,001 - 20,000"
        }
        else if(numberOfCases<=40000){
            return "20,001 - 40,000"
        }
        else{
            return "40,000 +"
        }

    }

    addLegend(){
        // console.log(data)
        var k =this
        var legendArray = [1000,5000,10000,20000,40000,50000]
                
        this.svg.append("text").text("Reported Cases").attr("x","450").attr("y","170").style("font-weight","bold")

        this.svg.selectAll("myDots").data(legendArray).enter().append("circle")
        .attr("cx","460")
        .attr("cy",function(d,i){return 200 + i*25})
        .attr("r","7")
        .style("fill",function(d,i){return k.getColor(d)})
        .style("stroke","black")
        
        this.svg.selectAll("myLabels").data(legendArray).enter().append("text")
        .attr("x","480")
        .attr("y",function(d,i){return 200 + i*25})
        .style("fill","black")
        .text(function(d,i){return k.getLegendText(d)})
        .attr("text-anchor", "left")
        .style("alignment-baseline", "middle")
    }

    
    render() {
        return (
                <div className="indiaMap" ref={ div => this.div = div } />

        )
    }
}
