import React, { Component } from 'react'
import { Chart } from "react-google-charts";
import Constants from "../constants.json"
import styled from "styled-components";

export default class StateChart extends Component {

        
    

    GetData() {

        
        let date
        let rt
        let data=[]
        let color='green'
        data.push(['date','Reproduction Value (Rt)'])

        date = this.props.data.date

        if (Object.keys(this.props.data).length === 0){
            rt = []
        }

        else{
            rt = this.props.data.rt
        
            for (var i=0;i<rt.length;i++)
            {
                data.push([new Date(date[i]),rt[i]])
            }
            
            if(rt[rt.length-1]>1)
                    color='red'
            this.currentRt=rt[rt.length-1]
            this.currentRt=this.currentRt.toFixed(2)
        }
        
    
    var l = this.props.dailyData.length
    
    this.newCases = this.props.dailyData[l-1][1];
    return [data,"Current Reproduction Value : "+this.currentRt,color];

    }


    constructor(props)
    {
        super(props)

        var k=this.GetData()
        this.changeChart = this.changeChart.bind(this);
        this.state={
            data : k[0],
            details:k[1],
            color:k[2],
            buttonText:'Click to see Daily Cases',
            showRt:true,
            allData:this.props.allData,
            width:this.setWidth()
        }

        
    }

    setWidth()
    {
        if(this.props.width<500)
            return this.props.width-20

        return 400;
    
    }

    changeChart()
    {
        if(this.state.showRt)
        {
            this.setState({
                buttonText:"Click to see Rt",
                showRt:false,
                details:"New Cases : "+this.newCases

            })
        }
        else{
            this.setState({
                buttonText:"Click to see Daily Cases",
                showRt:true,
                details:"Current Reproduction Value : "+this.currentRt

            })
        }
        
        
    }

    getFontSize = (textLength) => {
        
        if(textLength>19)
            return '1.5em'
        return '2em'
      }
      
    render() {
        if (Constants.statesWithNoRt.includes(this.props.name)){

        return(
            
            <GridView style={{marginBottom:"10%"}}>
                    <Heading  >{this.props.fullName}</Heading>

                    <DisabledButton style={{opacity:0.5,}} >Low number of cases to produce R<sub>t</sub></DisabledButton>

                    <Chart
                        width={this.state.width}
                        height={'300px'}
                        chartType="AreaChart"
                        loader={<div>Loading Chart</div>}
                        data={this.props.dailyData}
                        // data={this.props.dailyData}
                        options={{
                        colors:[this.state.color],
                            animation: {
                                startup: true,
                                easing: 'linear',
                                duration: 800,
                            },
                            // pointSize:5,
                            isStacked: true,
                            height: 300,
                            legend: { position: 'top', maxLines: 3 },
                            vAxis: { title:"Daily New Cases",titleTextStyle:{fontSize:15,bold:true,italic:false} },
                            hAxis: { title:"Date",titleTextStyle:{fontSize:15,bold:true,italic:false} },

                            // hAxis:{format:'dd/mm'},


                            crosshair: { orientation: 'vertical', trigger:'both' }
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        />
                        
                    
                    <div style={{fontWeight:500,fontSize:"large",color:this.state.color}}>{"New Cases: "+this.newCases}</div>
                    <div style={{fontWeight:500,fontSize:"large",color:this.state.color}}>Confirmed cases : {this.props.overall.confirmed}</div>

                </GridView>
        )
        }
        else{
            return (
                <GridView style={{marginBottom:"10%"}}>
                    <Heading >{this.props.fullName}</Heading>

                    <Button onClick={this.changeChart}>{this.state.buttonText}</Button>

                    {this.state.showRt ? 
                    (
                    <Chart
                        width={this.state.width}
                        height={'300px'}
                        chartType="AreaChart"
                        loader={<div>Loading Chart</div>}
                        data={this.state.data}
                        // data={this.props.dailyData}
                        options={{
                        colors:[this.state.color],
                            animation: {
                                startup: true,
                                easing: 'linear',
                                duration: 800,
                            },
                            // pointSize:5,
                            isStacked: true,
                            height: 300,
                            legend: { position: 'top', maxLines: 3 },
                            vAxis: { title:"Reproduction Value",titleTextStyle:{fontSize:15,bold:true,italic:false} },
                            hAxis: { title:"Date",titleTextStyle:{fontSize:15,bold:true,italic:false} },

                            // hAxis:{format:'dd/mm'},


                            crosshair: { orientation: 'vertical', trigger:'both' }
                        }}
                        rootProps={{ 'data-testid': '2' }}
                        />
                    ):(

                    <Chart
                        width={this.state.width}
                        height={'300px'}
                        chartType="AreaChart"
                        loader={<div>Loading Chart</div>}
                        data={this.props.dailyData}
                        // data={this.props.dailyData}
                        options={{
                        colors:[this.state.color],
                            animation: {
                                startup: true,
                                easing: 'linear',
                                duration: 800,
                            },
                            // pointSize:5,
                            isStacked: true,
                            height: 300,
                            legend: { position: 'top', maxLines: 3 },
                            vAxis: { title:"Daily New Cases",titleTextStyle:{fontSize:15,bold:true,italic:false} },
                            hAxis: { title:"Date",titleTextStyle:{fontSize:15,bold:true,italic:false} },

                            // hAxis:{format:'dd/mm'},


                            crosshair: { orientation: 'vertical', trigger:'both' }
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        />
                        
                    )}
                    <div style={{fontWeight:500,fontSize:"large",color:this.state.color}}>{this.state.details}</div>
                    <div style={{fontWeight:500,fontSize:"large",color:this.state.color}}>Confirmed cases : {this.props.overall.confirmed}</div>

                </GridView>
            )
        }
    }
}

const Heading = styled.h2`
    margin:0px;

`;

const Button = styled.div`

  background : white;
  border : solid 1px black;
  width:fit-content;
  margin:auto;
  padding:5px;
  border-radius : 5px;
  font-weight:500;


&:hover{
    box-shadow : 0px 0px 8px 0px #888;
    
};
`;
const DisabledButton = styled.div`

background : white;
border : solid 1px black;
width:fit-content;
margin:auto;
padding:5px;
border-radius : 5px;
font-weight:500;
opacity : 0.5;
`;

const GridView = styled.div`
    display:grid;
    grid-template-rows:50px 50px auto 30px 30px;
    /* grid-auto-rows: */
`;