import React, { Component } from 'react'
import styled from "styled-components";
import StateChart from './StateChart'
import Constants from "../constants.json"


export default class AllStateChart extends Component {


    ChartsAll() {

        var fullName={}
        var charts
        var lengthOfArray=Constants.states_cn.length
        for(let i =0; i<lengthOfArray;i++){
            fullName[Constants.states_cn[i]]=Constants.states_full[i]
        }

        
        var lengthOfArrayNoRt=Constants.statesWithNoRt.length
        for(let i =0; i<lengthOfArrayNoRt;i++){
            fullName[Constants.statesWithNoRt[i]]=Constants.statesWithNoRtFull[i]
        }

        var chartAll=Object.keys(this.props.data).map((st)=>
        <StateChart key={st} fullName={fullName[st]} name={st} data={this.props.data[st]} dailyData={this.getStateCases(st.toLowerCase()) }width={this.props.width} overall={this.getStateCasesStringFormatted(st)}/>
      )

        if(this.state.name==='')
        {
            return chartAll
        }
        else{
            var k =[]
            for(var key in fullName)
                if( key.toUpperCase().search(this.state.name)>-1 || fullName[key].toUpperCase().search(this.state.name)>-1)
                {
                    console.log(key);
                    
                    k.push(key)

                }
                
            console.log(k);
            
            charts=k.map((st)=>
            <StateChart key={st} fullName={fullName[st]} name={st} data={this.props.data[st]} dailyData={this.getStateCases(st.toLowerCase()) }width={this.props.width} overall={this.getStateCasesStringFormatted(st)}/>

            )       
            
        }
          
      return(charts)
    }
    constructor(props)
    {
        super(props) 
        this.state={
            allData:props.data,
            name:''
        }
        this.statesDaily=this.httpGet('https://data.covid19india.org/states_daily.json')
        
        this.changeName=this.changeName.bind(this)
        
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    getStateCasesStringFormatted(stateName){
        // console.log(stateName)
        var allCases = JSON.parse(this.props.stateCases)
        for (const element of allCases.statewise){
            if (element.statecode === stateName){
                return {
                    "confirmed":this.numberWithCommas(element.confirmed)
                }
            }

        }
        return 0        
    }



    componentDidMount(){

        var states_daily=JSON.parse(this.statesDaily).states_daily
        var deaths = states_daily.pop().tt
        var recovered= states_daily.pop().tt
        var confirmed = states_daily.pop().tt

        document.getElementById("newConfirmed").innerHTML = "+"+this.numberWithCommas(confirmed)
        document.getElementById("newRecovered").innerHTML = "+"+this.numberWithCommas(recovered)
        document.getElementById("newDeaths").innerHTML = "+"+this.numberWithCommas(deaths)
        
    }

    httpGet(theUrl){
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open( "GET", theUrl, false ); // false for synchronous request
        xmlHttp.send( null );
        return xmlHttp.responseText;
    }

    getStateCases(stateName){
        // console.log(this.stateCases)
        var allCases = JSON.parse(this.statesDaily)
        // console.log('allCases',allCases);
        var states_daily=allCases.states_daily
        var len=states_daily.length

        // console.log('len',states_daily[0]);
        var data=[['date','New Cases']]
        
        for (var i=0;i<len;i=i+3){
            data.push([new Date(states_daily[i].date),parseInt( states_daily[i][stateName])])
        }

        return data      
    }

    changeName(event)
    {
        event.preventDefault()
        this.setState({
            name:event.target.value.toUpperCase()
        })
        
    }
    
    render() {
        return (
            <div>
                <input value={this.state.name}  onChange={this.changeName}  type='text' style={{padding:5,outline:"none",borderRadius:50,fontSize:17,textAlign:"center",border:"2px solid black",minWidth:"250px", marginTop:"15px"}} placeholder='Search' />
                <AllStates className="AllStates" > 
                {this.ChartsAll()}
                </AllStates>

            </div>
        )
    }
}

const AllStates = styled.div`
  margin-top : 2%;
  display : grid;
  grid-template-columns : repeat(auto-fill, minmax(300px, 1fr));;
  grid-column-gap : 5%;

`;