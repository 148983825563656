import React, { Component } from 'react'
import firebase from '../firebase'
import styled from "styled-components";

import AllStateChart from './AllStateCharts';
import ReactLoading from 'react-loading';
import MainChart from './MainChart'
import MainChartVertical from './MainChartVertical'

import IndiaMap from './IndiaMap'
import AboutSection from "./AboutSection";
import ScrollToTop from './ScrollToTop';

export default class HomePage extends Component {



  httpGet(theUrl){
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", theUrl, false ); // false for synchronous request
    xmlHttp.send( null );
    return xmlHttp.responseText;
  }


    constructor(props)
    {
      super(props)
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      this.stateCases=this.httpGet('https://data.covid19india.org/data.json');
      this.state = {
        _statesData : null,
        database:null,
        width:0,
        height:0
      }
  
    }
    
  componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
   updateWindowDimensions() {
          this.setState({ width: window.innerWidth, height: window.innerHeight });
    } 
  componentWillMount()
  {
    
    var stateRef = firebase.database().ref().orderByChild('rt')
    stateRef.once('value',snapshot=>{
      var k=snapshot.val()
      // console.log('value',k);
      // console.log('length',Object.keys(k));

      this.setState({_statesData:k})
    })

    
  }
  componentDidMount()
  {
    
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

  }
    render() {
        return (
            <>
          <Container style={{textAlign:"center"}}>
          <ScrollToTop height={this.state.height}/>            



          {/* <img src={indiaFlag} alt="flag of India" style={{"height":"50px","width":"75px","float":"left","marginLeft":"80px"}}/> */}
          {/* <img src={indiaFlag} alt="flag of India" style={{"height":"50px","width":"75px","float":"right","marginRight":"80px"}}/> */}
          <Heading>R<sub>t</sub> Covid-19 for India</Heading>

            <br/>

          <OverallData>
            <Grid>
                <div>
                  <div  className="column">
                    <span className="totalHeading" style={{"color":"blue"}}>Confirmed</span>
                    <br/>
                    <span id="ttConfirmed" className="totalValue" style={{"color":"blue"}}>0</span>
                  </div>
                </div>
                <div>
                  <span id="newConfirmed" className="confrimedValue" style={{"color":"rgb(109, 32, 89)"}}>+0</span>
                </div>
            </Grid>


            <Grid>
              <div  className="column">
                <span className="totalHeading" style={{"color":"red"}}>Active</span>
                <br/>
                <span id="ttActive" className="totalValue" style={{"color":"red"}}>0</span>
              </div>

              <div></div>
            </Grid>


            <Grid>
              <div  className="column">
                <span className="totalHeading" style={{"color":"grey"}}>Deaths</span>
                <br/>
                <span id="ttDeaths" className="totalValue" style={{"color":"grey"}}>0</span>
              </div>
              <div  className="column">
                <span id="newDeaths" className="confrimedValue" style={{"color":"rgb(109, 32, 89)"}}>+0</span>
              </div>
            </Grid>

            <Grid>
              <div  className="column">
                <span className="totalHeading" style={{"color":"green"}}>Recovered</span>
                <br/>
                <span id="ttRecovered" className="totalValue" style={{"color":"green"}}>0</span>
              </div>

              <div  className="column">
                <span id="newRecovered" className="confrimedValue" style={{"color":"rgb(109, 32, 89)"}}>+0</span>
              </div>
            </Grid>

          </OverallData>



          <Question>What is R<sub>t</sub> ?</Question>
          <MainDescription style={{textAlign:"justify"}}>
            <b>Reproduction number (R<sub>t</sub>)</b> is an epidemiological number that, in simplest terms, represents how many people an infected individual will transmit the virus. An R<sub>t</sub> of 2 for coronavirus means that every infected person will infect two others, and those two people will each infect two more, and so on.
            <br/>
            <ul>
              <li>
                If R<sub>t</sub> is <span style={{color:"green"}}>less than 1</span>, each existing coronavirus infection causes less than one new infection. In this case, the <span style={{color:"green"}}>disease will decline</span> and eventually die out.
              </li>
              <li>
              If R<sub>t</sub> is <span style={{color:"red"}}>more than 1</span>, each existing coronavirus infection causes more than one new infection. The disease will <span style={{color:"red"}}>spread rapidly between people</span>, and there may be an outbreak or epidemic.
              </li>
            </ul>
          
          The value of R<sub>t</sub> helps us in two ways : 
          <ol>
            <li>
            It helps us understand the effectiveness of our measures in controlling an outbreak.
            </li>
            <li>
            It gives us vital information about whether we should increase or reduce restrictions based on our competing goals of economic prosperity and human safety.
            </li>
          </ol>

          </MainDescription>


        { this.state._statesData ?
          (
            
          <div id="appRoot">

            <h1 style={{marginBottom:"5px"}}>Current R<sub>t</sub> for States</h1>            

            {this.state.width<1000 ? (
            <MainChartVertical width={this.state.width} data={this.state._statesData}/>

            ) : (
             <MainChart data={this.state._statesData}/> 

            )}
            <h1 style={{marginBottom:"5px"}}>Current cases</h1>            
            <span style={{margin:"0px"}}>Last updated: </span>
            <span style={{color:"blue"}} id="lastUpdatedTxt"></span>
            <IndiaMap width={this.state.width} stateCases={this.stateCases}/>
            <h1 style={{marginBottom:"25px"}}>Reproduction value by states</h1>            

            <AllStateChart data={this.state._statesData} width={this.state.width} stateCases={this.stateCases}/>

            </div>) : 
          (
          <div>
            <div style={{margin:"auto",width:"fit-content",paddingTop:120}}>
            {/* <ThreeHorseLoading /> */}
            {/* <MeteorRainLoading/> */}
            <ReactLoading type="spin" color="blue" height={200} width={150}  />
          </div>
          <h1>Loading Data</h1>
          </div>) }


          
        </Container>
        
        <AboutSection />

      </>
        )
    }
}


const Heading = styled.h1`


`;

const Container = styled.div`
  margin:5%;
  font-family: 'Avenir', sans-serif;
  
`;
const MainDescription = styled.div`
  margin-left:5%;
  margin-right:5%;
  font-weight:500;
  font-size:20px;

`;

const Question =styled.div`
  font-weight:700;
  font-size:30px
`;

const OverallData = styled.div`

  display: grid;
  grid-template-columns : repeat(auto-fit, minmax(150px, 1fr));;
`;
const Grid = styled.div`
  margin-bottom:25px;
`;