import React, { Component } from 'react'
import * as d3 from 'd3v4';
import Constants from '../constants.json'

export default class MainChartVertical extends Component {

    componentDidMount()
    {
        this.initiallizeGraph()
        this.getData();
    }


    initiallizeGraph()
    {

        this.states = Constants.states_cn
        this.statesFull = Constants.states_full

        this.outerSvg = d3.select(this.div).append('svg').attr('width',this.props.width-30).attr('height',1200).attr('class','outSvg')

        this.innerSvg = this.outerSvg.append('svg').attr('width',this.props.width-40).attr('height',1125).attr('x',10)


        this.rect=this.innerSvg.append('rect').attr('stroke-width',2).attr('stroke','#d3d3d3')
        .attr('height',1080).attr('width',this.props.width-50).attr('fill','none').attr('y',25)

        //   var yScale = d3.scaleLinear().domain([0,4.5]).range([480,0])

        var xScale = d3.scaleLinear().domain([0,4.5]).range([0,this.props.width-50])
        // var yScale = d3.scaleLinear().domain([-1,21]).range([0,1000])

        this.x_axis = d3.axisTop().scale(xScale)
        this.x_axis_bottom = d3.axisBottom().scale(xScale)

        this.outerSvg.append("g")
        .attr("transform","translate(10,20)")
        .style("font-size",15)
        .call(this.x_axis);

        this.outerSvg.append("g")
        .attr("transform","translate(10,1115)")
        .style("font-size",15)
        .call(this.x_axis_bottom);
        
        this.outerSvg.append("text").attr("x", 10).attr("y", 1170).text("Last updated: ").style("font-size", "17px").attr("alignment-baseline","middle")
        this.outerSvg.append("text").attr("x", 120).attr("y", 1170).text("hekko").style("font-size", "17px").attr("alignment-baseline","middle").style("fill","blue").attr("id","lastUpdatedMainChartVertical")
        
        //Grid Lines
        this.innerSvg.append('g')
            .attr('class','grid')
            .attr("transform","translate(0,25)")
            .attr('opacity',0.3)
            .call(this.make_x_gridlines(xScale)
            .tickSize(-1080)
            .tickFormat("")
            )


    //   this.addLegend()

       
    }


    getData()
    {
      const states = Constants.states_cn
      // var k =null
      const tryData =[]
      states.map((st,i)=>{
          let value = this.props.data[st]
          var rt=value.rt
          var low_50=value.Low_50
          var low_90=value.Low_90
          var high_50=value.High_50
          var high_90=value.High_90
          var len=rt.length
          var col='green'
          

          // k={'rt':rt[len-1],'low_50':low_50[len-1],'high_50':high_50[len-1],'low_90':low_90[len-1],"high_90":high_90[len-1]}
          // console.log("k",k);
          if(rt[len-1]>1)
            col='red'
          tryData.push({"state_index":i,"low_50":low_50[len-1],"high_50":high_50[len-1],"low_90":low_90[len-1],"high_90":high_90[len-1],"col":col,"st":st,"rt":rt[len-1]})
      })
      // console.log("tryData",tryData.sort((a,b)=>d3.ascending(a.rt,b.rt)));

      var tryDataSorted = tryData.sort((a,b)=>d3.ascending(a.rt,b.rt))

      tryDataSorted.map((state,i)=>{
        // console.log(state.st);
        this.add50(i,state.low_50,state.high_50,state.low_90,state.high_90,state.col,state.st,state.rt,state.state_index)
        
      })
      this.addTootlTip()

      let value = this.props.data['MH']['date'].pop()
      value = value.split('/')
      document.getElementById("lastUpdatedMainChartVertical").textContent = value[1]+"/"+value[0]+"/"+value[2]

    }
    addTootlTip()
    {
      this.tooltip = d3.select(this.div)
        .append("div")
        .style("width","fit-content")
        .style("height","20px")
        .style("opacity", 0)
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "10px")
        .style("position","absolute")
    }

    add50(x,low50,high50,low90,high90,col,name,rt,state_index)
    {
    var xScale = d3.scaleLinear().domain([0,4.5]).range([0,this.props.width-50])
    var yScale = d3.scaleLinear().domain([-1,21]).range([0,1000])


      var mainG = this.innerSvg.append('g')
      .selectAll("dot")
      .data([x])
      .enter()

      // 50%CI - low rectangle
      // mainG.append("rect")
      // .attr("x", function (d) { return xScale(low50);  } )
      // .attr("y", function (d) { return yScale(x); } )
      // .attr("height", 10)
      // .attr("width",xScale(high50)-xScale(low50))
      // .style("fill", col)
      // .style('rx',5)
      // .style('opacity',1)

      // // 90% CI - high rectangles
      // mainG.append("rect")
      // .attr("x", function (d) { return xScale(low90);  } )
      // .attr("y", function (d) { return yScale(x); } )
      // .attr("width", xScale(high90)-xScale(low90))
      // .attr("height",10)
      // .style("fill", col)
      // .style('rx',5)
      // .style('opacity',0.5)    
    
      var th=this

      var mousemove = function(d) {
          th.tooltip
            .html(th.statesFull[state_index]+" : "+ rt.toFixed(2) )
            .style("left", (d3.event.pageX-80) + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
            .style("top", (d3.event.pageY-60) + "px")
            .style("opacity",1)
        }
      
        // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
      var mouseleave = function(d) {
          th.tooltip
            .transition()
            .duration(200)
            .style("opacity", 0)
            // .style("display",'none')
        }
      var mouseover = function(d) {
          th.tooltip
            .style("opacity", 1)
            // .style('display','block')
        }
      
    //   // code for Text

      var gg=mainG.append('g')
      .on("mouseover",mouseover)
      .on("mousemove",mousemove)
      .on("mouseleave",mouseleave)

      gg.append("rect")
      .attr("x", function (d) { return xScale(rt-0.1);  } )
      .attr("y", function (d) { return yScale(d-0.3); } )
      .attr("height", 18)
      .attr("width",28)
      .style('fill',col)
      .attr('stroke',col)
      .attr('stroke-width',2)
      

    //   // .text(name)

      gg.append('text')
      .style('color','black')
      .attr("x", function (d) { return xScale(rt);  } )
      .attr("y", function (d) { return yScale(d); } )
      .attr('font-size',12)
      .style('font-weight',700)
      .style('fill','white')
      .text(name)



    }



    make_x_gridlines(xScale) {		
        return d3.axisTop(xScale)
            .ticks(10)
    }

    render() {
        return (
            <div className="mainChart" ref={ div => this.div = div } />

        )
    }
}
