import React, { Component } from 'react'
import * as d3 from 'd3v4';
import Constants from '../constants.json'


export default class MainChart extends Component {
    constructor(props) {
        super(props);
        this.state={
          st:null
        }
      }
    


      componentDidMount()
      {  

        this.initiallizeGraph()
        this.getData()
        this.getLastUpdatedMainChart()
      }

      getLastUpdatedMainChart(){
        let value = this.props.data['MH']['date'].pop()
        value = value.split('/')
        
        document.getElementById("lastUpdatedMainChart").textContent = value[1]+"/"+value[0]+"/"+value[2]
      }

      getData()
      {
        const states = Constants.states_cn
        const tryData =[]
        states.map((st,i)=>{
            let value = this.props.data[st]
            var rt=value.rt
            var low_50=value.Low_50
            var low_90=value.Low_90
            var high_50=value.High_50
            var high_90=value.High_90
            var len=rt.length
            var col='green'
            

            if(rt[len-1]>1)
              col='red'
            tryData.push({"state_index":i,"low_50":low_50[len-1],"high_50":high_50[len-1],"low_90":low_90[len-1],"high_90":high_90[len-1],"col":col,"st":st,"rt":rt[len-1]})
        })

        var tryDataSorted = tryData.sort((a,b)=>d3.ascending(a.rt,b.rt))

        tryDataSorted.map((state,i)=>{
          this.add50(i,state.low_50,state.high_50,state.low_90,state.high_90,state.col,state.st,state.rt,state.state_index)
          
        })
      this.addTootlTip()
        
      }

    initiallizeGraph()
    {

      this.states = Constants.states_cn
      this.statesFull = Constants.states_full

      this.outerSvg = d3.select(this.div).append('svg').attr('width',1200).attr('height',600).attr('class','outSvg')

      this.innerSvg = this.outerSvg.append('svg').attr('width',1200).attr('height',500)


      this.rect=this.innerSvg.append('rect').attr('stroke-width',2).attr('stroke','#d3d3d3')
      .attr('height',480).attr('width',1010).attr('fill','none').attr('x',50).attr('y',10)

      var yScale = d3.scaleLinear().domain([0,4.5]).range([480,0])
      // var xScale = d3.scaleLinear().domain([0,10]).range([0,900])


      this.y_axis = d3.axisLeft()
                .scale(yScale);

      //y-axis
      this.outerSvg.append("g")
        .attr("transform","translate(30,10)")
        .call(this.y_axis);
    
      //GridLines
      this.innerSvg.append("g")			
        .attr("class", "grid")
        .attr("transform","translate(50,10)")
        .attr('opacity',0.3)
        .call(this.make_y_gridlines(yScale)
            .tickSize(-1010)
            .tickFormat("")
            )

      this.addLegend()
       
    }


    addTootlTip()
    {
      this.tooltip = d3.select(this.div)
        .append("div")
        .style("width","fit-content")
        .style("height","20px")
        .style("opacity", 0)
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "10px")
        .style("position","absolute")
    }

    addLegend()
    {
      this.outerSvg.append("rect").attr("x",50).attr("y",520).style("fill", "green").attr('height',15).attr('width',30).attr('opacity',0.5)
      this.outerSvg.append("rect").attr("x",200).attr("y",520).style("fill", "green").attr('height',15).attr('width',30)
      this.outerSvg.append("rect").attr("x",350).attr("y",520).style("fill", "red").attr('height',15).attr('width',30).attr('opacity',0.5)
      this.outerSvg.append("rect").attr("x",500).attr("y",520).style("fill", "red").attr('height',15).attr('width',30)

      this.outerSvg.append("text").attr("x", 85).attr("y", 529.5).text("90% CI").style("font-size", "15px").attr("alignment-baseline","middle")
      this.outerSvg.append("text").attr("x", 235).attr("y", 529.5).text("50% CI").style("font-size", "15px").attr("alignment-baseline","middle")
      this.outerSvg.append("text").attr("x", 385).attr("y", 529.5).text("90% CI").style("font-size", "15px").attr("alignment-baseline","middle")
      this.outerSvg.append("text").attr("x", 535).attr("y", 529.5).text("50% CI").style("font-size", "15px").attr("alignment-baseline","middle")
      this.outerSvg.append("text").attr("x", 875).attr("y", 529.5).text("Last updated: ").style("font-size", "15px").attr("alignment-baseline","middle")
      this.outerSvg.append("text").attr("x", 975).attr("y", 529.5).text("").style("font-size", "17px").attr("alignment-baseline","middle").style("fill","blue").attr("id","lastUpdatedMainChart")
      
    }

    add50(x,low50,high50,low90,high90,col,name,rt,state_index)
    {
      var yScale = d3.scaleLinear().domain([0,4.5]).range([480,0])
      var xScale = d3.scaleLinear().domain([-1,21]).range([0,900])

      var mainG = this.innerSvg.append('g')
      .selectAll("dot")
      .data([x])
      .enter()

      // 50%CI - low rectangle
      mainG.append("rect")
      .attr("x", function (d) { return xScale(d+1);  } )
      .attr("y", function (d) { return yScale(high50); } )
      .attr("height", yScale(low50)-yScale(high50))
      .attr("width",8)
      .style("fill", col)
      .attr('rx',5)
      .style('opacity',1)

      // 90% CI - high rectangles
      mainG.append("rect")
      .attr("x", function (d) { return xScale(d+1);  } )
      .attr("y", function (d) { return yScale(high90-0.05); } )
      .attr("height", yScale(low90)-yScale(high90))
      .attr("width",8)
      .style("fill", col)
      .attr('rx',5)
      .style('opacity',0.5)    
    
      var th=this

      var mousemove = function(d) {
          th.tooltip
            .html(th.statesFull[state_index]+" : "+ rt.toFixed(2) )
            .style("left", (d3.event.pageX-80) + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
            .style("top", (d3.event.pageY-60) + "px")
        }
      
        // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
      var mouseleave = function(d) {
          th.tooltip
            .transition()
            .duration(200)
            .style("opacity", 0)
            .style("display",'none')
        }
      var mouseover = function(d) {
          th.tooltip
            .style("opacity", 1)
            .style('display','block')
        }
      
      // code for Text

      var gg=mainG.append('g')
      .on("mouseover",mouseover)
      .on("mousemove",mousemove)
      .on("mouseleave",mouseleave)

      gg.append("rect")
      .attr("x", function (d) { return xScale(d+1-.2);  } )
      .attr("y", function (d) { return yScale(rt); } )
      .attr("height", 18)
      .attr("width",28)
      .attr('rx',5)
      .style('fill',"white").attr('stroke',col)
      

      // .text(name)

      gg.append('text')
      .style('color','black')
      .attr("x", function (d) { return xScale(d+1-.05);  } )
      .attr("y", function (d) { return yScale(rt-0.12); } )
      .attr('font-size',12)
      .style('user-select','none')
      .text(name)



    }
    

    make_y_gridlines(yScale) {		
        return d3.axisLeft(yScale)
            .ticks(10)
    }


    render() {
        return (

              <div className="mainChart" ref={ div => this.div = div } />
        )
    }
}
