import React, { Component } from 'react'
import styled from "styled-components";
import {  keyframes} from "styled-components";
export default class Demistify extends Component {
    componentWillMount()
    {
        window.document.body.style.background='black';
    }
    componentWillUnmount()
    {
        window.document.body.style.background='white';

    }
    render() {
        return (
            <>
            <Container>
                <Glitch >DEMYSTIFY R<sub>t</sub></Glitch>
            </Container>
            </>
        )
    }
}



const Container = styled.div`
  margin:5%;
  font-family: 'Raleway', sans-serif;
  background-color:black;
`;

const glitch = keyframes`
0% {
    transform: translate(0)
}
20% {
    transform: translate(-5px, 5px)
}
40% {
    transform: translate(-5px, -5px)
}
60% {
    transform: translate(5px, 5px)
}
80% {
    transform: translate(5px, -5px)
}
to {
    transform: translate(0)
}

`;
const Glitch= styled.div`

    &{
        font-weight: 700;
        font-size:8.125em;
        text-decoration: none;
        color: #fff;
        position: absolute;
        top: 5%;
        letter-spacing: 5px;
    }
    &:before{
        display: block;
        content: "DEMYSTIFY";
        position: absolute;
		top: 0;
		left: 0;
        height: 100%;
        width: 100%;
        opacity: .8;
    }
    &:after {
        display: block;
        content: "DEMYSTIFY";
        position: absolute;
		top: 0;
		left: 0;
        height: 100%;
        width: 100%;
        opacity: .8;
    }
    &:after {
        color: #f0f;
        z-index: -2;
    }
    &:before {
        color: #0ff;
        z-index: -1;
    }
    &:hover {
        &:before {
            animation: ${glitch} .3s cubic-bezier(.25, .46, .45, .94) both 2;
        }
        &:after {
            animation: ${glitch} .3s cubic-bezier(.25, .46, .45, .94) reverse both 2;
        }
    }
    }

    `;


