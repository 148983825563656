import React, { Component } from 'react'
import Demystify from './Components/Demystify';
import HomePage from './Components/HomePage';

import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
export default class App extends Component {
  render() {
    return (
      <>
      <Router>
        <Switch>
        <Route path="/" exact component={HomePage}></Route>
        <Route path="/demystify" component={Demystify}></Route>
        </Switch>
      </Router>    
      </>
    )
  }
}
