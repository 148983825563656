import React, { Component } from 'react'
import styled from "styled-components";

export default class AboutSection extends Component {
    render() {
        return (
            <>
            <Container>

                <h3 >About</h3>
                <div style={{ textAlign:"center",paddingBottom:30}}>
                    
                        <span >Developers</span> :
                         <SourceLink target='_blank' href="https://www.linkedin.com/in/lakshmatai/"> Laksh Matai</SourceLink>
                        , <SourceLink target='_blank' href="https://www.yashmatai.com/">Yash Matai</SourceLink> 
                        , <SourceLink target='_blank' href="https://www.linkedin.com/in/nileshmatai/">Nilesh Matai</SourceLink>
                            
                    

                    <br/>
                    <br/>

                    <SourceLink target='_blank' href="https://api.covid19india.org/">Data Source</SourceLink>
                    , <SourceLink   target='_blank' href="https://github.com/nilesh304/covid-19/blob/master/covid_rt.ipynb">Jupyter Notebook</SourceLink>
                    , <SourceLink   target='_blank' href="https://rt.live/">Inspiration</SourceLink>

                </div>
            </Container>

            </>
        )
    }
}
const Container = styled.div`
  padding:2%;
  font-family: 'Raleway', sans-serif;
  background:black;
  color:white;
  text-align:center
`;

const SourceLink = styled.a`

    text-decoration : none;
    color:white;
    border-bottom:1px solid orange;
    &:hover{
        color:orange;
    }
`;